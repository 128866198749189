/* eslint-disable no-undef */
import '../App.css';
import '../counter.css';
import React, { useEffect, useMemo, useState } from 'react';
import os from '../assets/os.png';
import twitter from '../assets/x.png';
import logo from '../assets/logo.png';
import eth from '../assets/ethereum.png';
import usd from '../assets/usdc.png';
import gif from '../assets/gif.gif';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, sepolia, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork, useWaitForTransaction } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther, webSocket } from 'viem';
import Countdown from 'react-countdown';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    /*	if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {*/
    // Render a countdowns

    if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

        window.location.reload(true);
        console.log("Mint Begins");
    }


    return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
    /*	}*/
};

const ops = () => {
    window.open("#");
}

const tweet = () => {
    window.open("#");
}

const homeLink = () => {
    window.open("#");
}

const website = () => {
    window.open("https://peyotepepe.pages.dev/");
}

//Polygon TEST Token - https://polygonscan.com/address/0xf53628dA0e3eeeCA9134e7ef3Ee99cB7C67a0667
//Polygon TEST NFT - 0x762e69d596746E2a8fE539914aDa8537e4091728

let ABI = [
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_initBaseURI",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "_initNotRevealedUri",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "_tokenContract",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "ApprovalCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApprovalToCurrentOwner",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ApproveToCaller",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "BalanceQueryForZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MintZeroQuantity",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "OperatorNotAllowed",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnerIndexOutOfBounds",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OwnerQueryForNonexistentToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TokenIndexOutOfBounds",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferCallerNotOwnerNorApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferFromIncorrectOwner",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToNonERC721ReceiverImplementer",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "TransferToZeroAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "URIQueryForNonexistentToken",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "approved",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "MAX_SUPPLY",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "OPERATOR_FILTER_REGISTRY",
        "outputs": [
            {
                "internalType": "contract IOperatorFilterRegistry",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "Tier1_referral_payments",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "Tier1_referrals",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "Tier1_referrer",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "Tier2_referral_payments",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "Tier2_referrals",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "Tier2_referrer",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "receiver",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "quantity",
                "type": "uint256[]"
            }
        ],
        "name": "airdrop",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "contractURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "count",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimalCorrector",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimalNumber",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "destinationAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "divider",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "divider2",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getBaseURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getChainlinkDataFeedLatestAnswer",
        "outputs": [
            {
                "internalType": "int256",
                "name": "",
                "type": "int256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getTheMintPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "max_per_wallet",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "ref",
                "type": "address"
            }
        ],
        "name": "mintInSameChainWithNative",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "ref",
                "type": "address"
            }
        ],
        "name": "mintInSameChainWithUSDT",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "mintPriceInUSDTInBips",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "notRevealedUri",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "quantity",
                "type": "uint256"
            }
        ],
        "name": "ownerMint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "publicMinted",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "public_mint_status",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "refRewardsPortion_Tier1",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "refRewardsPortion_Tier2",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "revealed",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_salePrice",
                "type": "uint256"
            }
        ],
        "name": "royaltyInfo",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "savedAddresses",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "savedQuantity",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_newBaseURI",
                "type": "string"
            }
        ],
        "name": "setBaseURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_contractURI",
                "type": "string"
            }
        ],
        "name": "setContractURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_df",
                "type": "address"
            }
        ],
        "name": "setDataFeed",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_MAX_SUPPLY",
                "type": "uint256"
            }
        ],
        "name": "setMAX_SUPPLY",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_max_per_wallet",
                "type": "uint256"
            }
        ],
        "name": "setMax_per_wallet",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_notRevealedURI",
                "type": "string"
            }
        ],
        "name": "setNotRevealedURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_receiver",
                "type": "address"
            },
            {
                "internalType": "uint96",
                "name": "_royaltyFeesInBips",
                "type": "uint96"
            }
        ],
        "name": "setRoyaltyInfo",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_tokenContract",
                "type": "address"
            }
        ],
        "name": "setTokenContract",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_decimalCorrector",
                "type": "uint256"
            }
        ],
        "name": "set_decimalCorrector",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_decimalNumber",
                "type": "uint256"
            }
        ],
        "name": "set_decimalNumber",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_dAddress",
                "type": "address"
            }
        ],
        "name": "set_destinationAddress",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_divider",
                "type": "uint256"
            }
        ],
        "name": "set_divider",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_divider2",
                "type": "uint256"
            }
        ],
        "name": "set_divider2",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_mintPriceInUSDTInBips",
                "type": "uint256"
            }
        ],
        "name": "set_mintPriceInUSDTInBips",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_refRewardsPortion_Tier1",
                "type": "uint256"
            }
        ],
        "name": "set_refRewardsPortion_Tier1",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_refRewardsPortion_Tier2",
                "type": "uint256"
            }
        ],
        "name": "set_refRewardsPortion_Tier2",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggleReveal",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "toggle_public_mint_status",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "token",
        "outputs": [
            {
                "internalType": "contract IERC20",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "tokenByIndex",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "tokenOfOwnerByIndex",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenWithdrawal",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "token_Contract",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }
];

let address = "0xFea9E14cDecAa9f7440C4598538a9637263a0DDF";
//let address = "0xE7268C3BC66b4dA84925D16110aE81391F26061d";

let ABI2 = [
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "authorizer",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            }
        ],
        "name": "AuthorizationCanceled",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "authorizer",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            }
        ],
        "name": "AuthorizationUsed",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_account",
                "type": "address"
            }
        ],
        "name": "Blacklisted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "newBlacklister",
                "type": "address"
            }
        ],
        "name": "BlacklisterChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "burner",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Burn",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "newMasterMinter",
                "type": "address"
            }
        ],
        "name": "MasterMinterChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "minter",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Mint",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "minter",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "minterAllowedAmount",
                "type": "uint256"
            }
        ],
        "name": "MinterConfigured",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "oldMinter",
                "type": "address"
            }
        ],
        "name": "MinterRemoved",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [],
        "name": "Pause",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "newAddress",
                "type": "address"
            }
        ],
        "name": "PauserChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "newRescuer",
                "type": "address"
            }
        ],
        "name": "RescuerChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "_account",
                "type": "address"
            }
        ],
        "name": "UnBlacklisted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [],
        "name": "Unpause",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "CANCEL_AUTHORIZATION_TYPEHASH",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DOMAIN_SEPARATOR",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "PERMIT_TYPEHASH",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "RECEIVE_WITH_AUTHORIZATION_TYPEHASH",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "TRANSFER_WITH_AUTHORIZATION_TYPEHASH",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "authorizer",
                "type": "address"
            },
            {
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            }
        ],
        "name": "authorizationState",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_account",
                "type": "address"
            }
        ],
        "name": "blacklist",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "blacklister",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "authorizer",
                "type": "address"
            },
            {
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "cancelAuthorization",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "authorizer",
                "type": "address"
            },
            {
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            },
            {
                "internalType": "bytes",
                "name": "signature",
                "type": "bytes"
            }
        ],
        "name": "cancelAuthorization",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "minter",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "minterAllowedAmount",
                "type": "uint256"
            }
        ],
        "name": "configureMinter",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "currency",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "decrement",
                "type": "uint256"
            }
        ],
        "name": "decreaseAllowance",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "increment",
                "type": "uint256"
            }
        ],
        "name": "increaseAllowance",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "tokenName",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "tokenSymbol",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "tokenCurrency",
                "type": "string"
            },
            {
                "internalType": "uint8",
                "name": "tokenDecimals",
                "type": "uint8"
            },
            {
                "internalType": "address",
                "name": "newMasterMinter",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "newPauser",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "newBlacklister",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "newName",
                "type": "string"
            }
        ],
        "name": "initializeV2",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "lostAndFound",
                "type": "address"
            }
        ],
        "name": "initializeV2_1",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "accountsToBlacklist",
                "type": "address[]"
            },
            {
                "internalType": "string",
                "name": "newSymbol",
                "type": "string"
            }
        ],
        "name": "initializeV2_2",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_account",
                "type": "address"
            }
        ],
        "name": "isBlacklisted",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "isMinter",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "masterMinter",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "mint",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "minter",
                "type": "address"
            }
        ],
        "name": "minterAllowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "nonces",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "pause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "pauser",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "signature",
                "type": "bytes"
            }
        ],
        "name": "permit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "deadline",
                "type": "uint256"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "permit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "validAfter",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "validBefore",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            },
            {
                "internalType": "bytes",
                "name": "signature",
                "type": "bytes"
            }
        ],
        "name": "receiveWithAuthorization",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "validAfter",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "validBefore",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "receiveWithAuthorization",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "minter",
                "type": "address"
            }
        ],
        "name": "removeMinter",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract IERC20",
                "name": "tokenContract",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "rescueERC20",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "rescuer",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "validAfter",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "validBefore",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            },
            {
                "internalType": "bytes",
                "name": "signature",
                "type": "bytes"
            }
        ],
        "name": "transferWithAuthorization",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "validAfter",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "validBefore",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "nonce",
                "type": "bytes32"
            },
            {
                "internalType": "uint8",
                "name": "v",
                "type": "uint8"
            },
            {
                "internalType": "bytes32",
                "name": "r",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "s",
                "type": "bytes32"
            }
        ],
        "name": "transferWithAuthorization",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_account",
                "type": "address"
            }
        ],
        "name": "unBlacklist",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "unpause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_newBlacklister",
                "type": "address"
            }
        ],
        "name": "updateBlacklister",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_newMasterMinter",
                "type": "address"
            }
        ],
        "name": "updateMasterMinter",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_newPauser",
                "type": "address"
            }
        ],
        "name": "updatePauser",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newRescuer",
                "type": "address"
            }
        ],
        "name": "updateRescuer",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "version",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    }
];

let address2 = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";

const maxSupply = 555;

const MintETH = () => {

    const { open } = useWeb3Modal()
    const { chain } = useNetwork()
    const { switchNetwork } = useSwitchNetwork()

    const { address: walletAddress } = useAccount({
        async onConnect() {
            handleConnect()
        }
    })

    //const transport = webSocket('wss://go.getblock.io/dacd4fb64cbc4bf593cb72a37be920b0')
    const transport = webSocket('https://go.getblock.io/2f7f084a1dcc473e8a410a11c627378e')

    const publicClient = createPublicClient({
        chain: mainnet,
        transport,
    })

    const [_totalSupply, settotalSupply] = useState(0)
    const [statusError, setstatusError] = useState("")
    const [statusLoading, setstatusLoading] = useState("")
    const [success, setsuccess] = useState("")

    const [approve_statusError, setapprove_statusError] = useState("")
    const [approve_statusLoading, setapprove_statusLoading] = useState("")
    const [approve_success, setapprove_success] = useState("")

    const [nftMintingAmount, setnftMintingAmount] = useState(1)
    const [_publicMintMsg, set_publicMintMsg] = useState("Mint Here")
    const [_cost, set_publicSaleCost] = useState("")
    const [_priceInUSDT, set_priceInUSDT] = useState(0)
    const [_wlcost, set_wlcost] = useState(0)
    const [_max_per_wallet, set_max_per_wallet] = useState(1)
    const [_owner, set_owner] = useState("")
    const [myNFTWallet, setmyNFTWallet] = useState(0)
    const [_public_mint_status, set_public_mint_status] = useState("")
    const [_MAX_SUPPLY, set_MAX_SUPPLY] = useState("")
    const [_navbarOpen, set_navbarOpen] = useState(0)
    const [_connected, setConnected] = useState(false)
    const [_freeMinLimit, set_OverallFreeMintLimit] = useState(600);
    const [showButton, setShowButton] = useState(false);
    const [_ref_Address, set_ref_Address] = useState('');
    const [_currentAllowance, setCurrentAllowance] = useState(0);
    const [_quantityOfAllowance, set_quantityOfAllowance] = useState(0);
    const [_increaseAllowanceAmount, setIncreaseAllowanceAmount] = useState(0);
    const [_allowanceStatus, setAllowanceStatus] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [_ErrorMsg1, setErrorMsg1] = useState("");
    const [showErrorDiv, setshowErrorDiv] = useState(false);

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }
    //........................................//
    const contract = {
        address: address,
        abi: ABI
    }

    var contract2 = {
        address: address2,
        abi: ABI2
    }

    async function handleConnect() {
        if (chain.id !== 1) {
            switchNetwork(1)
        }

        var data = await getBalance();
        setmyNFTWallet(Number(data.data));
        console.log("myNFTWallet :" + data.data);
        setConnected(true);
    }

    const { refetch: getTotalSupply } = useContractRead({
        ...contract,
        functionName: 'totalSupply',
    })

    const { refetch: getCost } = useContractRead({
        ...contract,
        functionName: 'getTheMintPrice',
    })

    const { refetch: getPriceInUSDT } = useContractRead({
        ...contract,
        functionName: 'mintPriceInUSDTInBips',
    })

    const { refetch: getBalance } = useContractRead({
        ...contract,
        functionName: 'balanceOf',
        args: [walletAddress ? walletAddress : '0x']
    })

    useEffect(() => {

        if (statusError) {
            const timer = setTimeout(() => {
                setstatusError(false);  // Assuming you have a setter to change the state
            }, 2000); // 4 minutes in milliseconds

            // Cleanup the timeout when the component unmounts or the statusError changes
            return () => clearTimeout(timer);
        }

        if (showErrorDiv) {
            const timer = setTimeout(() => {
                setshowErrorDiv(false);  // Assuming you have a setter to change the state
            }, 2000); // 4 minutes in milliseconds

            // Cleanup the timeout when the component unmounts or the statusError changes
            return () => clearTimeout(timer);
        }

        if (approve_statusError) {
            const timer = setTimeout(() => {
                setapprove_statusError(false);  // Assuming you have a setter to change the state
            }, 2000); // 4 minutes in milliseconds

            // Cleanup the timeout when the component unmounts or the statusError changes
            return () => clearTimeout(timer);
        }

        async function extractLink() {
            try {
                if (window.location.href != null) {

                    let currentUrl = window.location.href;
                    console.log("currentUrl" + currentUrl);

                    // https://dtc-mint.pinkribbons.club/?ref=0x6334BAE02114C080F05E6D58b65A1d7926FbbeBc

                    let arr = currentUrl.split("0x");
                    console.log("arr:" + "0x" + arr[1]);

                    set_ref_Address("0x" + arr[1]);

                }
            } catch (err) {
                console.log(err);
            }
        }

        async function fetchData() {
            var data = await getTotalSupply();

            settotalSupply(Number(data.data))
            console.log("totalSupplyFromUseffect : " + data.data)

        }
        async function fetchData2() {

            var data1 = await getBalance();
            setmyNFTWallet(Number(data1.data));
            console.log("myNFTWallet :" + data1.data);
        }

        async function fetchData3() {

            var data2 = await getCost();
            set_publicSaleCost(Number(data2.data));
            console.log("publicSaleCost2 :" + data2.data);
        }

        async function fetchData4() {

            var data2 = await getPriceInUSDT();
            set_priceInUSDT(Number(data2.data));
            await new Promise(resolve => setTimeout(resolve, 2000));

            const data = await getAllowance();
            setCurrentAllowance(Number(data.data));

            console.log("current allowance : " + (Number(data.data)));
            console.log("USDT Value 22 : " + (nftMintingAmount * Number(data2.data) * 10 ** 4));

            if (Number(data.data) >= (nftMintingAmount * Number(data2.data) * 10 ** 4)) {
                setAllowanceStatus(true);
                //Show the repay button
                console.log("ALLOWANCE STATUS: TRUE");

            } else {
                setAllowanceStatus(false);
                //Show the approve button
                console.log("ALLOWANCE STATUS: FALSE");
            }
        }

        extractLink();

        if (_connected) {
            fetchData();
            fetchData2();
            fetchData3();
            fetchData4();
            //allowanceCheck();
        }

        // eslint-disable-next-line no-use-before-define
    }, [approve_statusError, showErrorDiv, statusError, _connected, getBalance, getCost, getPriceInUSDT, getTotalSupply, _priceInUSDT, nftMintingAmount]);

    const { data, refetch, isSuccess } = useContractReads({
        contracts: [
            { ...contract, functionName: 'totalSupply' },
            { ...contract, functionName: 'max_per_wallet' },
            { ...contract, functionName: 'owner' },
            { ...contract, functionName: 'getTheMintPrice' },
            { ...contract, functionName: 'MAX_SUPPLY' },
            { ...contract, functionName: 'public_mint_status' },

        ]
    },)

    useMemo(() => {

        if (isSuccess === true) {
            settotalSupply(Number(data[0].result))
            set_max_per_wallet(Number(data[1].result))
            //set_wlcost(formatEther(data[2].result))
            set_owner(data[2].result)
            //set_publicSaleCost(formatEther(data[4].result))
            set_publicSaleCost(Number(data[3].result)); // Convert WEI to ETH
            set_MAX_SUPPLY(data[4].result)
            set_public_mint_status(data[5].result)
        }
    }, [_totalSupply, data, isSuccess])

    //........................................//

    async function onPlus() {

        console.log("_public_mint_status :" + _public_mint_status);
        var nftIhave = nftMintingAmount + myNFTWallet;


        console.log("myNFTWallet : " + myNFTWallet);
        console.log("nftMintingAmount : " + nftMintingAmount);

        if (_public_mint_status === true) {
            console.log("2");

            if (nftIhave < _max_per_wallet) {
                //if (nftIhave < 1) {
                console.log("3");

                setnftMintingAmount(nftMintingAmount + 1);
                console.log("4");


                //}
            }
        }
    }

    async function onMinus() {
        if (nftMintingAmount != 1) {
            setnftMintingAmount(nftMintingAmount - 1)
        }
    }

    const { writeAsync } = useContractWrite({
        ...contract,
        onError(error) {
            if (error.message.includes('balance')) {
                setstatusError(true)
                setstatusLoading(false)
            }
        }
    })

    async function onMint() {
        try {
            var mintCost;
            var fullTotalSupply = 0;
            fullTotalSupply = (nftMintingAmount) + (_totalSupply);
            console.log("fullTotalSupply : " + fullTotalSupply);

            if (_public_mint_status) {
                //mintCost =  parseFloat(_cost)
                mintCost = _cost;
                console.log("mintCostB : " + mintCost);
            }

            mintCost = (mintCost * nftMintingAmount).toString();
            console.log("nftMintingAmountA : " + nftMintingAmount)

            console.log("mintCostA : " + mintCost);

            setstatusLoading(true)
            setstatusError(false)

            var fullCost = (Number(_cost) * nftMintingAmount * 105 / 100).toFixed(0);
            console.log("ref_Address :" + _ref_Address);

            var referral = _ref_Address;
            if (_ref_Address === '0xundefined') {
                referral = walletAddress;
            }

            console.log("referral_input :" + referral);
            console.log("fullCost :" + fullCost);


            var res = await writeAsync({
                functionName: 'mintInSameChainWithNative',
                args: [nftMintingAmount, referral],
                value: fullCost // mintCost
            })
            var result = await publicClient.waitForTransactionReceipt(res)
            if (result.status === 'success') {
                setstatusError(false)
                setsuccess(true)
                setstatusLoading(false)
                setshowErrorDiv(false);
                await new Promise(resolve => setTimeout(resolve, 5000));
                window.location.reload(true);

            } else if (result.status) {
                setsuccess(false)
                setstatusError(true)
                setstatusLoading(false)
                setshowErrorDiv(false);
            }

        } catch (e) {
            console.error("Transaction failed:", e);
            if (e.message.includes("Transaction with hash")) {

                setsuccess(true);
                await new Promise(resolve => setTimeout(resolve, 5000));
                window.location.reload(true);
            }
            if (e.message.includes("err: insufficient funds for gas")) {
                //setErrorMsg1("Insufficient funds");
                setshowErrorDiv(true); // Show the error div for insufficient funds
                setstatusError(false);
                setstatusLoading(false);
            } else if (e.message.includes("User rejected the request")) {
                //setErrorMsg1("User Rejected");
                setshowErrorDiv(false);
                setstatusError(false);
                setstatusLoading(false);
            } else {
                //setErrorMsg1("Sorry, something went wrong");
                setshowErrorDiv(false);
                setstatusError(true);
                setstatusLoading(false);
            }

        }
    }

    async function onMintUSDT() {
        try {
            var mintCost;
            var fullTotalSupply = 0;
            fullTotalSupply = (nftMintingAmount) + (_totalSupply);
            console.log("fullTotalSupply : " + fullTotalSupply);

            setstatusLoading(true);
            setstatusError(false);

            var referral = _ref_Address;
            if (_ref_Address === '0xundefined') {
                referral = walletAddress;
            }

            console.log("referral_input :" + referral);

            var res = await writeAsync({
                functionName: 'mintInSameChainWithUSDT',
                args: [nftMintingAmount, referral],
                value: 0,
                gasLimit: 685000
            })
            var result = await publicClient.waitForTransactionReceipt(res)
            if (result.status === 'success') {
                setstatusError(false)
                setsuccess(true)
                setstatusLoading(false)
                setshowErrorDiv(false);
                await new Promise(resolve => setTimeout(resolve, 5000));
                window.location.reload(true);
            } else if (result.status) {
                setsuccess(false)
                setstatusError(true)
                setstatusLoading(false)
                setshowErrorDiv(false);
            }

        } catch (e) {
            console.error("Transaction failed:", e);
            if (e.message.includes("Transaction with hash")) {

                setsuccess(true);

                await new Promise(resolve => setTimeout(resolve, 5000));
                window.location.reload(true);
            }
            if (e.message.includes("No Sufficient Tokens")) {
                //setErrorMsg1("Insufficient funds");
                setshowErrorDiv(true); // Show the error div for insufficient funds
                setstatusError(false);
                setstatusLoading(false);
            } else if (e.message.includes("User rejected the request")) {
                //setErrorMsg1("User Rejected");
                setshowErrorDiv(false);
                setstatusError(false);
                setstatusLoading(false);
            } else {
                //setErrorMsg1("Sorry, something went wrong");
                setshowErrorDiv(false);
                setstatusError(true);
                setstatusLoading(false);
            }

        }

    }

    function shortenAddress(walletAddress) {
        try {
            return _connected
                ? walletAddress.slice(0, 3) + "..." + walletAddress.slice(-4)
                : "Connect";
        } catch (error) {
            console.log(error);
        }
    }

    const { refetch: getAllowance } = useContractRead({
        ...contract2,
        functionName: 'allowance', args: [walletAddress, address]
    })

    async function allowanceCheck() {

        console.log("allowanceCheck1");
        const data = await getAllowance();
        setCurrentAllowance(Number(data.data));
        console.log("allowanceCheck2");

        if (Number(data.data) >= Number(nftMintingAmount * _priceInUSDT * 10 ** 6)) {
            setAllowanceStatus(true);
            //Show the repay button
            console.log("setAllowanceStatus : " + setAllowanceStatus);
            console.log("allowanceCheck3");
            console.log("Allowance Check---: " + Number(data.data));
            console.log("Price in USDT ss : " + _priceInUSDT);
            console.log("USDT Value : " + Number(nftMintingAmount * _priceInUSDT * 10 ** 6));

        } else {
            setAllowanceStatus(false);
            //Show the approve button
            console.log("allowanceCheck4");

        }
    }

    async function allowance() {
        try {
            setapprove_statusLoading(true);
            setapprove_statusError(false);

            // Calculate the required allowance amount
            const requiredAllowance = Number(nftMintingAmount * _priceInUSDT * 10 ** 4);
            console.log("__ETH_requiredAllowance : " + requiredAllowance);

            let quantityOfAllowance;

            async function fetch_quantityOfAllowance() {
                quantityOfAllowance = Number(requiredAllowance) + Number(_currentAllowance);
                console.log("__ETH_quantityOfAllowance : " + quantityOfAllowance);
                console.log("__ETH_currentAllowance : " + _currentAllowance);
                set_quantityOfAllowance(quantityOfAllowance);
            }

            await fetch_quantityOfAllowance();

            await new Promise(resolve => setTimeout(resolve, 1500));
            console.log("__ETH_quantityOfAllowance_await : " + quantityOfAllowance.toString());

            // Set allowance directly with the approve function
            const res = await writeAsync({
                ...contract2,
                functionName: 'approve',
                args: [address, quantityOfAllowance],
                gasLimit: '685000',
            });

            // Wait for the transaction to be mined
            const result = await publicClient.waitForTransactionReceipt(res);
            if (result.status === 'success') {
                setapprove_statusError(false);
                setapprove_success(true);
                setapprove_statusLoading(false);

                await new Promise(resolve => setTimeout(resolve, 3000));
                window.location.reload();
            } else {
                setapprove_success(false);
                setapprove_statusError(true);
                setapprove_statusLoading(false);
            }

        } catch (e) {
            console.error("Transaction failed:", e);
            if (e.message.includes("Transaction with hash")) {

                setapprove_success(true);

                await new Promise(resolve => setTimeout(resolve, 3000));
                window.location.reload(true);
            }
            if (e.message.includes("No Sufficient Tokens")) {
                //setErrorMsg1("Insufficient funds");
                setshowErrorDiv(true); // Show the error div for insufficient funds
                setstatusError(false);
                setstatusLoading(false);
            } else if (e.message.includes("User rejected the request")) {
                setapprove_success(false);
                setapprove_statusError(false);
                setapprove_statusLoading(false);
            } else {
                setapprove_success(false);
                setapprove_statusError(true);
                setapprove_statusLoading(false);
            }

        }
    }


    const handleCopy = () => {
        navigator.clipboard.writeText(`https://lineart.pages.dev/${walletAddress}`)
            .then(() => {
                // Show notification
                const notification = document.getElementById('copy-notification');
                notification.classList.add('show');
                setTimeout(() => notification.classList.remove('show'), 3000); // Hide after 3 seconds

                // Update button text to checkmark ✔
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 3000); // Reset after 3 seconds
            })
            .catch(error => console.error('Failed to copy address:', error));
    };


    return (
        <div class="allWrap">
            <div class="light">
                <div class="cont">
                    <div class="headers">

                        <div class="headers2">

                            <div class="logo"><img class="logoPic" src={logo} onClick={website} /></div>

                            <div class="right">

                                <div class="icons">
                                    <div class="socialIcon"><img onClick={ops} src={os} /></div>
                                    <div class="socialIcon"><img onClick={tweet} src={twitter} /></div>
                                </div>

                                <div class="connect2">
                                    <Web3Button />
                                </div>

                            </div>

                        </div>

                    </div>
                    <div class="introduction">

                        <div class="in2">
                            <div class="intro">
                                The rare Pepe and Friends <br /> Collection original
                            </div>

                            <div class="intro2">
                                <p></p>
                                <p>The first NFT collection to include Pepe's friends in the fun, nostalgia, and mind manifesting. With just 4269 NFTs available and rare NFTs earning special prizes, this collection is the first of it's kind, and very lucrative for holders. A combination of Rare Pepe and Peyote Pepe is the rarest NFT in the collection.</p>
                            </div>

                            <div id="copy-notification" class="notification">Copied and ready!!</div>

                            {walletAddress !== undefined ?
                                <div className="container">
                                    <div className="container2" onClick={handleCopy}>
                                        <div className="address-section">
                                            <p>Get your referral address:</p>
                                            <code>https://lineart.pages.dev/{shortenAddress(walletAddress)}</code>
                                        </div>
                                        <button className="copy-button">
                                            {isCopied ? '✔' : 'Copy'}
                                        </button>
                                    </div>
                                </div>
                                : null}

                            <div class="nftblockWalletConnectedALL">
                                {walletAddress === undefined ?
                                    <div class="walletConnect">

                                        <button onClick={() => { open() }} class="wallet3" >MINT NOW</button>

                                    </div>
                                    :
                                    null}

                            </div>
                        </div>

                        {walletAddress === undefined ?

                            (<div class="nftPicDiv">
                                <img class="nftPic" src={gif} alt='gif' />
                            </div>) : (

                                (<div class="mintDiv">
                                    <div class="totalSupply">{_totalSupply} / 4269</div>

                                    {(!_public_mint_status) ? (
                                        <div className='price'>🔴 Minting is Paused! 🔴</div>
                                    ) : (
                                        <div className='price'>🟢 Minting is Live! 🟢</div>
                                    )}


                                    <div class="minting_count_button">

                                        <div class="center">

                                            <button onClick={onMinus} class="btnfos-0-2" type="submit">-</button>

                                        </div>

                                        <div>
                                            <div class="nftminter2">{nftMintingAmount}</div>
                                        </div>


                                        <div class="center">
                                            <button onClick={onPlus} class="btnfos-0-2" type="submit">+</button>

                                        </div>
                                    </div>

                                    {_max_per_wallet <= myNFTWallet ?

                                        (<div class="price">
                                            {walletAddress != _owner ?
                                                <div>Limit Reached! ⚠️</div> : null}
                                        </div>) :
                                        (<div class="price"><div>You can mint {Number(_max_per_wallet) - Number(myNFTWallet)} more</div></div>)}

                                    {(_max_per_wallet > myNFTWallet && _public_mint_status) ?

                                        <div class="mintbuttondiv">
                                            <button class="btnfos-0-3" onClick={onMint}>
                                                Mint For {(_cost / 10 ** 18).toFixed(2) * nftMintingAmount} ETH <img src={eth} />
                                            </button>

                                            {_allowanceStatus ?
                                                <button class="btnfos-0-3" onClick={onMintUSDT}>
                                                    Mint For {nftMintingAmount * (_priceInUSDT) / 100} USDC <img src={usd} />
                                                </button> :

                                                <button class="btnfos-0-3" onClick={allowance}>
                                                    Approve USDC <img src={usd} />
                                                </button>}

                                        </div> :

                                        <div class="mintbuttondiv">
                                            <button class="btnfos-0-3" onClick={onMint} disabled>
                                                Mint For {(_cost / 10 ** 18).toFixed(2)} ETH <img src={eth} />
                                            </button>

                                            <button class="btnfos-0-3" disabled>
                                                Mint For {nftMintingAmount * (_priceInUSDT) / 100} USDC <img src={usd} />

                                            </button>

                                        </div>
                                    }

                                    <div>
                                        <p></p>

                                        {statusError ? (
                                            <div class="errorMessage">
                                                <div >Sorry, something went wrong <br /> please try again later</div>
                                            </div>)
                                            : null}

                                        {statusLoading ? (
                                            <div class="loadingContainer">
                                                <div class="loadingText">Minting your NFT</div>
                                            </div>)
                                            : null}

                                        {showErrorDiv ? (
                                            <div class="errorMessage">
                                                <div >Insufficient funds for gas price</div>
                                            </div>)
                                            : null}

                                        {success ? (
                                            <div>
                                                <div class="successfully">🐸 Minting Successful! 🐸</div>
                                            </div>
                                        )
                                            : null}

                                    </div>

                                    <div>
                                        <p></p>
                                        {approve_statusError ? (
                                            <div class="errorMessage">
                                                <div >Sorry, something went wrong <br /> please try again later</div>
                                            </div>)
                                            : null}

                                        {approve_statusLoading ? (
                                            <div class="loadingContainer">
                                                <div class="loadingText">Approving</div>
                                            </div>)
                                            : null}

                                        {approve_success ? (
                                            <div class="successfully">Appproved ✅</div>
                                        )
                                            : null}

                                    </div>

                                </div>

                                )
                            )}
                    </div>

                </div>

            </div >
        </div >
    )

}
export default MintETH;
